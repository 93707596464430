<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
// import DatePicker from "vue2-datepicker";
// import ChangeDate from "@/components/changeDateComponent";
import btnComponent from "@/components/btnComponent";
// import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มขออนุมัติไฟแนนซ์",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    // ChangeDate,
    btnComponent,
    // vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มขออนุมัติไฟแนนซ์",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: "ขออนุมัติไฟแนนซ์",
          active: false,
          href: "/loanApplication",
        },
        {
          text: "เพิ่มขออนุมัติไฟแนนซ์ (หรือแก้ไข)",
          active: true,
        },
      ],
      brand: "",
      bookingDcPrice: 0,
      // branchId:'',
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        // {
        //   key: "qnCode",
        //   sortable: true,
        //   label: "qnCode",
        // },
        {
          key: "customerNameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "customerFamilyNameTh",
          sortable: true,
          label: "สกุล",
        },
        {
          key: "bookingCode",
          sortable: true,
          label: "รหัสจอง",
        },
        // {
        //   key: "vin",
        //   sortable: true,
        //   label: "เลขตัวถัง",
        // },
        {
          key: "productNameTh",
          sortable: true,
          label: "ชื่อสินค้า",
        },
        {
          key: "bookingDate",
          sortable: true,
          label: "วันที่จอง",
        },
        // {
        //   key: "grandTotalPrice",
        //   label: "ราคา",
        //   class: "text-end",
        // },
        {
          key: "status",
          label: "สถานะ",
          class: "text-center",
        },
        {
          key: "branchName",
          label: "สาขา",
        },
      ],
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      overlayFlag: false,
      submitform: false,
      rows: [],
      bookingId: "",
      nameState: null,
      optionBranch: [],
      filter: {
        bookingCode: "",
        customer: "",
        startDate: "",
        endDate: "",
        status: "",
        userBranchId: "",
      },
      userBranchId: "",
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      loading: false,
      sortBy: "age",
      sortDesc: false,
      filterOn: [],
      selected: "",
      rowsPO: "",
      selectMode: "single",
    };
  },
  validations: {
    bookingId: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.optionBranch = arrayBranchAll;
    // if (this.$route.params.loanId) {
    //   this.showDetail();
    //   this.showBookingDetails();
    // }
  },
  created() {
    this.getLocalData();
    this.getData();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },
    getData() {
      // this.overlayFlag = true;
    //   console.log(this.filter.userBranchId.branchId)
      useNetw
        .get("api/loan-application/branch-vehicle-booking", {
          params: {
            page: this.currentPage,
            perPage: 10,
            branchId: this.branchId != null ? this.branchId: this.filter.userBranchId.branchId,
            bookingCode: this.filter.bookingCode
          },
        })
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alert(bookingCode) {
      Swal.fire({
        title: "ต้องการเลือกใบจองนี้ใช่หรือไม่ ?",
        text: bookingCode,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.submitCredit();
        }
      });
    },
    handleSearch() {
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    onRowSelected(rowsPO) {
      if (rowsPO[0] != undefined) {
        this.bookingId = rowsPO[0].bookingId;
        this.alert(rowsPO[0].bookingCode);
      }
      // this.$refs["modalSearch"].hide();
    },
    submitCredit() {
      this.loading = true;
      useNetw
        .post("api/loan-application/store", {
          bookingId: this.bookingId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalSearch"].hide();
          this.$router.push({
            name: "detail-loan",
            params: { loanId: btoa(response.data.loanId) },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>
<style>
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}

.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="!this.$route.params.loanId">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search                      :custom-label="customLabelProduct" -->
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <code> * </code> ใบจอง :
                    <multiselect
                      v-model="bookingId"
                      label="bookingCode"
                      type="search"
                      :options="rows"
                      disabled
                      open-direction="bottom"
                      placeholder="ชื่อสินค้า"

                      :class="{
                        'is-invalid': submitform && $v.bookingId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitform && $v.bookingId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.bookingId.required">{{ errormessage }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-1 text-left">
                  &nbsp; <br />
                  <button class="btn btn-primary" v-b-modal.modalSearch>
                    <i class="uil uil-search-plus"></i>
                  </button>
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-lg-12">
                  <div class="text-end">
                    <button class="btn btn-primary">
                      <i class="uil uil-search-plus"></i>
                    </button>
                    <button class="btn btn-primary m-2">
                      บันทึก
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal
      ref="modalSearch"
      id="modalSearch"
      title="สร้างสอบถามข้อมูลเครดิต"
      hide-footer
      size="xl"
      centered
      :state="nameState"
    >
      <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12">
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <code> * </code>สาขา:
                    <multiselect
                      v-model="filter.userBranchId"
                      label="nameTh"
                      type="search"
                      :options="optionBranch"
                      :show-labels="false"
                      open-direction="bottom"
                      placeholder=""
                      @input="getData"
                    >
                      <span slot="noResult">ไม่พบข้อมูล</span>
                    </multiselect>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-3">
                  <div class="mb-3 position-relative">
                    รหัสใบจองสินค้า:
                    <b-form-input
                      v-model="filter.bookingCode"
                      type="search"
                      class="form-control"
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 text-end">
                    &nbsp;
                    <!-- <b-button
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              :to="{ name: 'create-vehicle-po' }"
                            >
                              <i class="uil-plus"></i>
                              สั่งซื้อรถเข้าสต็อก
                            </b-button> -->
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-3">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  แสดงผล&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    @input="handlePageChange"
                  ></b-form-select
                  >&nbsp;รายการ
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- End search -->
      </div>
      <!-- Table -->
      <b-skeleton-wrapper :loading="loading">
        <!-- skeleton  -->
        <template #loading>
          <b-skeleton-table></b-skeleton-table>
        </template>
        <div class="table-responsive mb-0">
          <b-table
            :items="rows"
            :fields="fields"
            :current-page="1"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            responsive="sm"
            ref="selectableTable"
            selectable
            :select-mode="selectMode"
            @row-selected="onRowSelected"
          >
            <template #cell(index)="rowsPO">
              {{ rowsPO.index + 1 }}
            </template>
            <template #cell(status)="rowsPO">
              <span v-if="rowsPO.item.status === 'APV'" class="badge bg-success"
                >ยืนยันแล้ว</span
              >
              <span v-if="rowsPO.item.status === 'SUBMIT'" class="badge bg-info"
                >รออนุมัติ</span
              >
              <span v-if="rowsPO.item.status === 'CAN'" class="badge bg-danger"
                >ยกเลิกแล้ว</span
              >
              <span v-if="rowsPO.item.status === 'DFT'" class="badge bg-warning"
                >ร่าง</span
              >
            </template>
          </b-table>

          <!-- <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p> -->
        </div>
      </b-skeleton-wrapper>
      <div class="row">
        <div class="col">
          <div class="align-items-center">
            แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
            {{ this.rowsPO.total }} รายการ
          </div>
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rowsPO.total"
                :per-page="perPage"
                @change="handleChangePage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end model -->
  </Layout>
</template>
